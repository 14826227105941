import React from 'react';
import { Helmet } from "react-helmet";
import ldbLogo from '../assets/img/LDB-Construction-White.svg';
import laptop from '../assets/img/home-hero@2x.jpg';
import mobile from '../assets/img/home-hero-mobile@2x.jpg';

class Home extends React.Component {
    state = {
        hide: false,
        backgroundImage: laptop // Default to laptop image
    };

    componentDidMount() {
        this.updateBackgroundImage();
        window.addEventListener('resize', this.updateBackgroundImage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBackgroundImage);
    }

    updateBackgroundImage = () => {
        const isTabletOrMobile = window.innerWidth <= 1024; // Adjust breakpoint for tablet or mobile
        this.setState({ backgroundImage: isTabletOrMobile ? mobile : laptop });
    };

    hideSection = () => {
        setTimeout(() => {
            this.setState({ hide: true });
        }, 250);
    };

    render() {
        const { backgroundImage, hide } = this.state;

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "WebSite",
                    "@id": "https://www.ldbconstruction.com/#website",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "description": "LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area.",
                    "potentialAction": [
                        {
                            "@type": "SearchAction",
                            "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    ],
                    "inLanguage": "en-US"
                },
                {
                    "@type": "WebPage",
                    "@id": "https://www.ldbconstruction.com/#webpage",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "isPartOf": {
                        "@id": "https://www.ldbconstruction.com/#website"
                    },
                    "datePublished": "2014-02-26T15:20:02+00:00",
                    "dateModified": "2020-12-20T08:25:41+00:00",
                    "inLanguage": "en-US",
                    "potentialAction": [
                        {
                            "@type": "ReadAction",
                            "target": [
                                "https://www.ldbconstruction.com/"
                            ]
                        }
                    ]
                }
            ]
        };

        if (hide) {
            setTimeout(() => {
                window.location.href = '/about';
                console.log('redirect');
            }, 1000);
        }

        return (
            <React.Fragment>
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top', // Ensure top of the image is always shown
                        position: 'relative', // Enables positioning for inner elements
                    }}
                >
                    <Helmet>
                        <title>Home - LDB Construction UK Ltd</title>
                        <link rel="canonical" href={window.location} />
                        <meta name="robots" content="index, follow" />
                        <meta
                            name="description"
                            content="LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area."
                        />
                        <script type="application/ld+json">
                            {JSON.stringify(ldJson)}
                        </script>
                    </Helmet>

                    <div
                        className={`_landing-section ${hide ? '_hide' : ''}`}
                        onClick={this.hideSection}
                        onWheel={this.hideSection}
                        onTouchMove={this.hideSection}
                        style={{ position: 'relative' }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
